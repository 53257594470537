import React, { Component } from 'react'
import './About.css'

class About extends Component {
  render () {
    return (
      <div className='about'>
        <h1>Edit Some page</h1>
      </div>
    )
  }
}

export default About
